import React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import StartProject from '../components/Common/StartProject'

const FAQ = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Frequently Asked Questions"  
            />
            <div className="ptb-100">
            {/* <div className="section-title">
                    <h2>Frequently Asked Questions</h2>
            </div> */}
                <div className="container">
                    <div className="faq-accordion">
                        <Accordion>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Do I need a credit card to use Tafoma?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p><strong>No</strong>, you can join Tafoma for free, all you need is an email address. The free package that Tafoma offers has no time limitations. If this package suits your team you can use it forever with no issues.</p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        What is the learning curve of the Tafoma app?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>Very easy. The architectural components of Tafoma loosely couples the underlying repository and the user interface. In other words, we developed Tafoma to have intuitive and user-friendly UI/UX design that makes the platform to be almost self-explanatory.</p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        How to access the app?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>You can easily access Tafoma from any device, if you want to use Tafoma trough a browser just go to <strong><a href="https://app.tafoma.com"><u>https://app.tafoma.com</u></a></strong>. Tafoma is also available on mobile just click the links bellow to download it.</p>
                                    <p><a href="https://apps.apple.com/us/app/tafoma/id1137130236"><img src="https://tafoma.com/wp-content/uploads/2020/12/5a902db97f96951c82922874.png" alt="" width="148" height="50" /></a></p>
                                    <p><a href="https://play.google.com/store/apps/details?id=tafoma.com"><img src="https://tafoma.com/wp-content/uploads/2020/12/en_badge_web_generic-e1608207658747.png" alt="" width="150" height="44" /></a></p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        How to create an account?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <ul>
                                        <li>To star, go to Tafoma&rsquo;s <strong><a href="https://app.tafoma.com">Sign Up page</a></strong>. Click on the Sign Up button.</li>
                                        <li> Now you can enter your personal information.</li>
                                        <li>That's it you are in!</li>
                                    </ul>
                                    <p><sub>*The Workspace name you set is for your personal workspace. <a href="https://tafoma.com/help-center/">More about Workspaces</a>.</sub></p>
                                    <p><img src="https://tafoma.com/wp-content/uploads/2020/12/ezgif.com-video-to-gif.gif" alt="" width="403" height="250" /></p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            {/* <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Creating a Workspace
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>Creating a Workspace in Tafoma is easy, but it is important to understand how to mange more Workspaces, we recommend you check out this article on Workspaces.</p>
                                    <ul>
                                        <li>To create a Workspace go into Tafoma and click on the <strong>More</strong> tab.</li>
                                        <li>Than click on&nbsp;<strong>Workspaces</strong></li>
                                        <li>In the new window that opened, click <strong>New Workspace</strong></li>
                                        <li>Enter the name and click <strong>Create</strong>&nbsp;</li>
                                    </ul>
                                    <p><img src="https://tafoma.com/wp-content/uploads/2020/12/ScreenFlow.gif" alt="" width="487" height="300" /></p>
                                    <ul>
                                        <li>Tafoma transfers you into the new workspace and you can start inviting team members (invite Members).</li>
                                    </ul>
                                </AccordionItemPanel>
                            </AccordionItem> */}

                            {/* <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Inviting Members
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>In order for Tafoma to help you with Team-Collaboration, you need to invite your team members to one Workspace.</p>
                                    <ul>
                                        <li>Inviting someone to Tafoma can be done from the <strong>+</strong> or from the Teams tab.</li>
                                        <li>All you need to do is <strong>enter their email address</strong> and select a team from which they are part of. (not mandatory)</li>
                                        <li>The new member that you invited will receive an email that will take them to the Sing-Up page and after they Sign-Up it will take them to the workspace. If the person is already registered in Tafoma alongside the email they will also receive a notification in Tafoma.</li>
                                        <li>Bellow that you can see the Permissions that you can allow this member to have, Read More about Permissions bellow.</li>
                                    </ul>
                                    <p><img src="https://tafoma.com/wp-content/uploads/2020/12/ScreenFlow2.gif" alt="" width="406" height="250" /></p>
                                    <h4>Permissions</h4>
                                    <p>When inviting a member or editing a member that has been invited by you, you can chose what things they can work on.</p>
                                    <p>Having the <strong>Project</strong> Permission off means that they can&rsquo;t make Projects in the Workspace. They will be able to see projects which are shared with them. <br />The same applies to <strong>Contacts</strong> and <strong>Tasks</strong>.</p>
                                    <p><br /><strong>Invite Members</strong>, this allows/disallows the new member to invite other members to the workspace. (We recommend turning this ON only for team-leaders)</p>
                                    <p><br /><strong>See Other Members</strong>, here you chose if the new member can see the Other Members in the workspace.</p>
                                    <p><br /><strong>External Followers</strong>, this allows/disallows the new member to invite external followers on task or projects.</p>
                                </AccordionItemPanel>
                            </AccordionItem> */}

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    What are the uses and the benefits of a non-integrative team communication and project management platform like Tafoma?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>Essentially, Tafoma as project management and team communication software is employed by numerous industries for project planning, time management, improved communication, resource allocation, and change management.</p>
                                    <p>However, the non-integrative function of Tafoma allows cost-effectiveness and seamless architecture that helps SMEs companies in becoming as productive and competitive as possible, and sets them apart from the rest of the industry.</p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        What is the difference between the Project and Task tool on Tafoma?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>Project management and task management on Tafoma go hand in hand.</p>
                                    <p>Projects on Tafoma is an accessible and efficient hub, where you can see how a project is coming along - from  conception to completion, they contain milestones and a clear outcome.  It gives you the overview of all the tasks.</p>
                                    <p>Tasks are single units of work, on the individual level to complete a project. Tasks on Tafoma can be comprised of: description, comments, to-do lists, expenses, and time management section.</p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        What is the mobile version of Tafoma like?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>In designing our Tafoma mobile interface we paid particular attention to a number of principles for intuitive interaction: suitability for the task, compatibility, consistency, gestalt laws (how the mind groups similar elements together based on their shape, colour, size and brightness), feedback and self-descriptiveness. Our non-integrative platform also doesn’t require syncing with third party software which allows seamless work of the platform.</p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        How secure are my files and documents, and what is the data protection policy of Tafoma?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>In line with the online privacy policies, we take pride in Tafoma providing dual level encryption so your collected, shared and stored information remains private and secure. Our SSL certificate offers full data protection.</p>
                                </AccordionItemPanel>
                            </AccordionItem>

                        </Accordion>
                    </div>
                </div>
            </div>
            <StartProject />
            <Footer />
        </Layout>
    );
}

export default FAQ