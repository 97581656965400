import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"

const StartProject = () => {
    return (
        <div className="project-start-area tasksmanager ptb-50">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="text-center">
                            <StaticImage src={'../../assets/images/homepage/contact.svg'} 
                                        alt="Two people shaking hands." 
                                        formats={["AUTO", "WEBP", "AVIF"]}
                                        placeholder="none"
                                        loading="eager"/>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="project-start-content">
                            <h2>Connect With Us</h2>
                            <p>If you have any further questions, feel free to contact us. We are always here for you and would love to talk.</p>

                            <Link to="/contact" className="default-btn"> 
                                Contact Us 
                                <span></span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StartProject